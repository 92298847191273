<div class="modal fade" id="studentSchedules" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content bd-25">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Schedule">Schedule</h5>
            <button (click)="clearModalData()" *ngIf="(loading$ | async) === false" type="button" class="close mt-22"
               data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <button (click)="closeScheduleChildModal()" type="button" class="close" #closeScheduleModal
               data-dismiss="modal" aria-label="Close">
            </button>
            <div class="modal-body p-0">
               <div class="container-fluid whiteBg p-0">
                  <div class="tab-content">
                     <div id="Schedule" class="tab-pane fade in active">
                        <div class="ReactModal__Content Modal-content-after-open BookingModal-tt" tabindex="-1"
                           role="dialog">
                           <div class="BookingModal-tt ModalCore-c28f0z-0 display-MT">
                              <div class="Wrapper-pro" data-qa-id="wrapper-modal">
                                 <div class="Timetable-CL">
                                    <div class="TimetableHead-CL" data-qa-id="timetable">
                                       <div class="row">
                                          <div class="nxt_frwd">
                                             <div class="col-md-1">
                                                <button *ngIf="reverseButtonAccess()" id="reverseDate"
                                                   (click)="reverseDate()"
                                                   class="NavButton-CL NavButtonDisabled-CL Button-button-CL Button-outlined-CL"
                                                   data-qa-id="previous-timeslots">
                                                   <svg height="12" viewBox="0 0 8 12" width="8"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path
                                                         d="M3.268 5.854l4.293 4.292-1.415 1.415L.44 5.854 6.146.146l1.415 1.415z">
                                                      </path>
                                                   </svg>
                                                </button>
                                             </div>
                                             <div class="col-md-1" style="margin-left:-5px;">
                                                <button id="forwardDate" (click)="forwardDate()"
                                                   class="NavButton-CL Button-button-CL Button-outlined-CL"
                                                   data-qa-id="next-timeslots">
                                                   <svg height="12" viewBox="0 0 8 12" width="8"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path
                                                         d="M4.732 5.854L.44 1.56 1.854.146 7.56 5.854 1.854 11.56.439 10.146z">
                                                      </path>
                                                   </svg>
                                                </button>
                                             </div>
                                             <div class="col-md-4 textline2 text-center no_wrap pl-0"
                                                style="padding-top:5px;">
                                                <span>{{month1}}</span>&nbsp;
                                                <span *ngIf="month2">-{{month2}}</span>
                                                <span>{{FirstDate}}-</span>
                                                <span>{{SeventhDate}},</span>
                                                <span>{{currentYear}}</span>
                                             </div>
                                          </div>
                                          <div class="col-md-6">
                                             <input readonly class="form-control" value="{{timezone}}">
                                             <!-- - {{time1 | date:'HH:mm:ss'}} -->
                                          </div>
                                       </div>
                                    </div>
                                    <div>
                                       <div class="DaysHead-CL">
                                          <div class="DayName-CL Day-date-CL" id="FirstDay">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FirstDay}}</p>
                                                <p class="DayDate-CL">{{FirstDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SecondDay}}</p>
                                                <p class="DayDate-CL">{{SecondDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{ThirdDay}}</p>
                                                <p class="DayDate-CL">{{ThirdDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FouthDay}}</p>
                                                <p class="DayDate-CL">{{FourthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FifthDay}}</p>
                                                <p class="DayDate-CL">{{FifthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SixthDay}}</p>
                                                <p class="DayDate-CL">{{SixthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SeventhDay}}</p>
                                                <p class="DayDate-CL">{{SeventhDate}}</p>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="Calendar-CL">
                                          <div class="Day-date-CL">
                                             <div *ngFor="let firstDateTiming of FirstDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FirstDate}}-{{firstDateTiming}}-{{getMonthForDate(weekFirstDate)}}"
                                                (click)="selectDate( FirstDate , firstDateTiming , weekFirstDate)"
                                                [ngClass]="{'badge' : checkDate(FirstDate, firstDateTiming , getMonthForDate(weekFirstDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFirstDate, firstDateTiming)}">
                                                {{firstDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let secondDateTiming of SecondDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SecondDate}}-{{secondDateTiming}}-{{getMonthForDate(weekSecondDate)}}"
                                                (click)="selectDate( SecondDate , secondDateTiming , weekSecondDate)"
                                                [ngClass]="{'badge' : checkDate(SecondDate, secondDateTiming , getMonthForDate(weekSecondDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSecondDate, secondDateTiming)}">
                                                {{secondDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let thirdDateTiming of ThirdDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{ThirdDate}}-{{thirdDateTiming}}-{{getMonthForDate(weekThirdDate)}}"
                                                (click)="selectDate( ThirdDate , thirdDateTiming , weekThirdDate)"
                                                [ngClass]="{'badge' : checkDate(ThirdDate, thirdDateTiming, getMonthForDate(weekThirdDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekThirdDate, thirdDateTiming)}">
                                                {{thirdDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let fourthDateTiming of FourthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FourthDate}}-{{fourthDateTiming}}-{{getMonthForDate(weekFourthDate)}}"
                                                (click)="selectDate( FourthDate , fourthDateTiming , weekFourthDate)"
                                                [ngClass]="{'badge' : checkDate(FourthDate, fourthDateTiming, getMonthForDate(weekFourthDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFourthDate, fourthDateTiming)}">
                                                {{fourthDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let fifthDateTiming of FifthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FifthDate}}-{{fifthDateTiming}}-{{getMonthForDate(weekFifthDate)}}"
                                                (click)="selectDate( FifthDate , fifthDateTiming , weekFifthDate)"
                                                [ngClass]="{'badge' : checkDate(FifthDate, fifthDateTiming, getMonthForDate(weekFifthDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFifthDate, fifthDateTiming)}">
                                                {{fifthDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let sixthDateTiming of SixthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SixthDate}}-{{sixthDateTiming}}-{{getMonthForDate(weekSixthDate)}}"
                                                (click)="selectDate( SixthDate , sixthDateTiming , weekSixthDate)"
                                                [ngClass]="{'badge' : checkDate(SixthDate, sixthDateTiming, getMonthForDate(weekSixthDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSixthDate, sixthDateTiming)}">
                                                {{sixthDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let seventhDateTiming of SeventhDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SeventhDate}}-{{seventhDateTiming}}-{{getMonthForDate(weekSeventhDate)}}"
                                                (click)="selectDate( SeventhDate , seventhDateTiming , weekSeventhDate)"
                                                [ngClass]="{'badge' : checkDate(SeventhDate, seventhDateTiming, getMonthForDate(weekSeventhDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSeventhDate, seventhDateTiming)}">
                                                {{seventhDateTiming}}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div *ngIf="bookingDetails" class="InstantBookingFooter-CL text-left">
                                 <div
                                    *ngIf="bookingType == 'requestReschedule' || (bookingDetails.booking_details && bookingDetails.booking_details.overDueReschedule == 'overDueReschedule')"
                                    class="form-group">
                                    <div class="col-lg-12">
                                       <textarea id="requestReschedule" rows="6" cols="80"
                                          class="form-control"></textarea>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-sm-6">
                                       <p><span trans="Selected Lessons:">Selected Lessons: {{selectedLessons}}</span>
                                       </p>
                                       <p><span trans="Lessons To Select:">Lessons To Select: {{lessonsToSelect}}</span>
                                       </p>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div *ngIf="bookingType == 'requestReschedule'" class="TT-formGroup">
                                       <button [disabled]="loading$ | async" (click)="requestReschedule()"
                                          id="assignDates" type="button" disabled class="TT-primaryBTN"
                                          trans="Request Reschedule" style="width:auto;padding:8px 35px;">
                                          Request Reschedule
                                          <app-loader *ngIf="loading$ | async"></app-loader>
                                       </button>
                                    </div>
                                    <div *ngIf="bookingDetails">
                                       <div *ngIf="!bookingDetails.booking_details" class="TT-formGroup">
                                          <button (click)="rescheduleThisDates()" id="assignDates" type="button"
                                             disabled class="TT-primaryBTN" trans="Reschedule Dates"
                                             style="width:auto;padding: 8px 35px;">Reschedule Dates</button>
                                       </div>
                                    </div>
                                    <div *ngIf="bookingDetails">
                                       <div *ngIf="bookingDetails.booking_details" class="TT-formGroup">
                                          <button
                                             *ngIf="bookingDetails.booking_details.overDueReschedule == 'overDueReschedule'"
                                             (click)="rescheduleOverDueDates()" id="assignDates" type="button" disabled
                                             class="TT-primaryBTN" trans="Reschedule"
                                             style="width:auto;padding: 8px 35px;">Reschedule</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>